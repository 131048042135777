import { AsOfDateText, type IconType } from "@clipboard-health/ui-components";
import { InformationSection, InformationSectionTextContent } from "@clipboard-health/ui-components";
import { type FacilityNote } from "@src/appV2/Facilities/types";
import { parseISO } from "date-fns";

interface PolicyInformationSectionProps {
  note: FacilityNote["note"];
  updatedAt: FacilityNote["updatedAt"];
  iconType: IconType;
  title: string;
  id: string;
}

export function PolicyInformationSection(props: PolicyInformationSectionProps) {
  const { note, updatedAt, iconType, title, id } = props;

  if (!note) {
    return null;
  }

  return (
    <InformationSection iconType={iconType} title={title} id={id}>
      <AsOfDateText date={updatedAt ? parseISO(updatedAt) : new Date()} sx={{ paddingBottom: 4 }} />
      <InformationSectionTextContent>{note}</InformationSectionTextContent>
    </InformationSection>
  );
}

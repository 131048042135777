import { AsOfDateText } from "@clipboard-health/ui-components";
import { Text, type TextProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, type SxProps, type Theme } from "@mui/material";
import { type FacilityNote } from "@src/appV2/Facilities/types";

import { LateCancellationText } from "./LateCancellationText";
import { SentHomeChargesText } from "./SentHomeChargesText";

interface CancellationPoliciesSectionProps {
  validAsOfDate: Date;
  cancellationWorkplaceNote?: FacilityNote["note"];
  lateCancellationPeriod?: number;
  lateCancellationFeeHours?: number;
  sentHomeChargeHours?: number;
  textVariant?: TextProps["variant"];
  sx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
  color?: (theme: Theme) => string | undefined;
}

export function CancellationPoliciesSection(props: CancellationPoliciesSectionProps) {
  const {
    validAsOfDate,
    cancellationWorkplaceNote,
    lateCancellationPeriod,
    lateCancellationFeeHours,
    sentHomeChargeHours,
    color: propsColor,
    textVariant = "body2",
    sx,
    textSx,
  } = props;

  const color: CancellationPoliciesSectionProps["color"] = (theme) => {
    if (isDefined(propsColor)) {
      return propsColor(theme);
    }

    return theme.palette.text.secondary;
  };

  return (
    <Stack spacing={3} overflow="auto" sx={sx}>
      <AsOfDateText date={validAsOfDate} sx={{ paddingBottom: 4 }} />

      <LateCancellationText
        color={color}
        lateCancellationPeriod={lateCancellationPeriod ?? 0}
        lateCancellationFeeHours={lateCancellationFeeHours ?? 0}
        variant={textVariant}
        sx={textSx}
      />
      <SentHomeChargesText
        color={color}
        sentHomeChargeHours={sentHomeChargeHours ?? 0}
        variant={textVariant}
        sx={textSx}
      />

      {isDefined(cancellationWorkplaceNote) && (
        <Text color={color} variant={textVariant} sx={textSx}>
          {cancellationWorkplaceNote}
        </Text>
      )}
    </Stack>
  );
}

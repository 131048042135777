import { InformationSection } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, DialogContent, Stack } from "@mui/material";
import { useGetExtraTimePaySettings } from "@src/appV2/Facilities/api/useGetExtraTimePaySettings";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { FloatingGoToTopButton } from "@src/appV2/redesign/components/FloatingGoToTopButton";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { parseISO } from "date-fns";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";

import { useScrollToTopOnStatusTap } from "../../useScrollToTopOnStatusTap";
import { WorkplaceNoteIdentifier } from "../constants";
import type { WorkplaceProfile } from "../types";
import { useWorkplaceNotesParsed } from "../useWorkplaceNotesParsed";
import { BreaksSection } from "./BreaksSection";
import { CancellationPoliciesSection } from "./CancellationPoliciesSection";
import { ExtraTimePaySection } from "./ExtraTimePaySection";
import { PolicyInformationSection } from "./PolicyInformationSection";

interface WorkplacePoliciesModalProps {
  modalState: UseModalState;
  workplace: WorkplaceProfile;
}

export function WorkplacePoliciesModal(props: WorkplacePoliciesModalProps) {
  const { modalState, workplace } = props;
  const {
    userId: workplaceId,
    lateCancellation,
    sentHomeChargeHours = 0,
    requiresLunchBreak,
    requiresLunchBreakSince,
    providesRestBreaks,
    providesRestBreaksSince,
  } = workplace;

  const { data: notes = {} } = useWorkplaceNotesParsed(workplaceId!);

  const {
    data: extraTimePaySettings,
    isError: extraTimePayIsError,
    isLoading: extraTimePayIsLoading,
  } = useGetExtraTimePaySettings(workplaceId);

  const cancelInstructionsNote = notes[WorkplaceNoteIdentifier.CANCEL_INSTRUCTIONS];
  const dressCodeNote = notes[WorkplaceNoteIdentifier.DRESS_CODE_INSTRUCTIONS];
  const importantInformationNote = notes[WorkplaceNoteIdentifier.PROFILE_DESCRIPTION];
  const mandatoryBreakNote = notes[WorkplaceNoteIdentifier.MANDATORY_BREAK_POLICY];
  const restBreakNote = notes[WorkplaceNoteIdentifier.REST_BREAK_POLICY];

  const hasBreaks =
    (requiresLunchBreak === true && isDefined(mandatoryBreakNote)) ||
    (providesRestBreaks === true && isDefined(restBreakNote));

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { ref: listRef, inView: listIsVisible } = useInView();

  useScrollToTopOnStatusTap(scrollContainerRef);

  return (
    <FullScreenDialog
      skipTopSafeAreaPadding
      modalState={modalState}
      variant="primary"
      sx={{ overflow: "hidden" }}
    >
      <StickyScrollPageHeader
        title="Workplace Policies"
        scrollContainerRef={scrollContainerRef}
        isLoading={false}
      />

      <DialogContent ref={scrollContainerRef} sx={{ padding: 7, paddingTop: 0 }}>
        <FloatingGoToTopButton
          isVisible={!listIsVisible}
          onClick={() => {
            scrollContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />

        <Stack spacing={10}>
          {/* This is just a placeholder to detect whether we scroll and go to top button should be visible */}
          <Box ref={listRef} />

          {(isDefined(cancelInstructionsNote?.note) || isDefined(lateCancellation)) && (
            <InformationSection
              iconType="remove"
              title="Cancellation Policies"
              id="cancellation-policies"
            >
              <CancellationPoliciesSection
                validAsOfDate={
                  cancelInstructionsNote?.updatedAt
                    ? parseISO(cancelInstructionsNote.updatedAt)
                    : new Date()
                }
                cancellationWorkplaceNote={cancelInstructionsNote?.note}
                lateCancellationPeriod={lateCancellation?.period ?? 0}
                lateCancellationFeeHours={lateCancellation?.feeHours ?? 0}
                sentHomeChargeHours={sentHomeChargeHours}
                textVariant="body1"
                textSx={{ lineHeight: 1.25 }}
                sx={{ overflow: "visible" }}
              />
            </InformationSection>
          )}

          {hasBreaks && (
            <InformationSection iconType="break" title="Breaks" id="breaks">
              <BreaksSection
                requiresLunchBreak={requiresLunchBreak}
                requiresLunchBreakSince={requiresLunchBreakSince}
                providesRestBreaks={providesRestBreaks}
                providesRestBreaksSince={providesRestBreaksSince}
                workplaceNotes={notes}
              />
            </InformationSection>
          )}

          {isDefined(extraTimePaySettings) && (
            <InformationSection iconType="extra-pay" title="Extra Time Pay" id="extra-time-pay">
              <ExtraTimePaySection
                settings={extraTimePaySettings}
                isError={extraTimePayIsError}
                isLoading={extraTimePayIsLoading}
              />
            </InformationSection>
          )}

          {isDefined(dressCodeNote?.note) && (
            <PolicyInformationSection
              id="dress-code"
              iconType="dress-code"
              title="Dress Code"
              note={dressCodeNote.note}
              updatedAt={dressCodeNote.updatedAt}
            />
          )}

          {isDefined(importantInformationNote?.note) && (
            <PolicyInformationSection
              id="other-policies"
              iconType="info"
              title="Other Policies"
              note={importantInformationNote.note}
              updatedAt={importantInformationNote.updatedAt}
            />
          )}
        </Stack>
      </DialogContent>
    </FullScreenDialog>
  );
}

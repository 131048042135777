import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, DialogContent, Stack } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { FloatingGoToTopButton } from "@src/appV2/redesign/components/FloatingGoToTopButton";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";

import { useScrollToTopOnStatusTap } from "../../useScrollToTopOnStatusTap";
import { WorkplaceNoteIdentifier } from "../constants";
import { useWorkplaceNotesParsed } from "../useWorkplaceNotesParsed";
import { PolicyInformationSection } from "./PolicyInformationSection";

interface WorkplaceCheckInInstructionsModalProps {
  modalState: UseModalState;
  workplace: Facility;
}

export function WorkplaceCheckInInstructionsModal(props: WorkplaceCheckInInstructionsModalProps) {
  const { modalState, workplace } = props;
  const { userId: workplaceId } = workplace;

  const { data: notes = {} } = useWorkplaceNotesParsed(String(workplaceId));

  const parkingNote = notes[WorkplaceNoteIdentifier.PARKING_INSTRUCTIONS];
  const entranceNote = notes[WorkplaceNoteIdentifier.ENTRANCE_INSTRUCTIONS];
  const earlyCheckInNote = notes[WorkplaceNoteIdentifier.EARLY_CHECK_IN_INSTRUCTIONS];
  const timecardNote = notes[WorkplaceNoteIdentifier.TIMECARD_INSTRUCTIONS];
  const firstShiftNote = notes[WorkplaceNoteIdentifier.FIRST_SHIFT_INSTRUCTIONS];
  const orientationNote = notes[WorkplaceNoteIdentifier.ORIENTATION_INSTRUCTIONS];
  const otherNote = notes[WorkplaceNoteIdentifier.OTHER_INSTRUCTIONS];

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { ref: listRef, inView: listIsVisible } = useInView();

  useScrollToTopOnStatusTap(scrollContainerRef);

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <StickyScrollPageHeader
        title="Check-In Instructions"
        scrollContainerRef={scrollContainerRef}
        isLoading={false}
      />

      <DialogContent ref={scrollContainerRef} sx={{ padding: 7, paddingTop: 0 }}>
        <FloatingGoToTopButton
          isVisible={!listIsVisible}
          onClick={() => {
            scrollContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />

        <Stack spacing={10}>
          {/* This is just a placeholder to detect whether we scroll and go to top button should be visible */}
          <Box ref={listRef} />

          {isDefined(parkingNote?.note) && (
            <PolicyInformationSection
              id="parking"
              iconType="car"
              title="Parking"
              note={parkingNote.note}
              updatedAt={parkingNote.updatedAt}
            />
          )}
          {isDefined(entranceNote?.note) && (
            <PolicyInformationSection
              id="entrance"
              iconType="exit"
              title="Entrance"
              note={entranceNote.note}
              updatedAt={entranceNote.updatedAt}
            />
          )}
          {isDefined(earlyCheckInNote?.note) && (
            <PolicyInformationSection
              id="early-check-in"
              iconType="passkeys"
              title="Early Check-In"
              note={earlyCheckInNote.note}
              updatedAt={earlyCheckInNote.updatedAt}
            />
          )}
          {isDefined(timecardNote?.note) && (
            <PolicyInformationSection
              id="timecard"
              iconType="date-clock"
              title="Time Card"
              note={timecardNote.note}
              updatedAt={timecardNote.updatedAt}
            />
          )}
          {isDefined(firstShiftNote?.note) && (
            <PolicyInformationSection
              id="first-shift"
              iconType="priority"
              title="First Shift"
              note={firstShiftNote.note}
              updatedAt={firstShiftNote.updatedAt}
            />
          )}
          {isDefined(orientationNote?.note) && (
            <PolicyInformationSection
              id="orientation"
              iconType="compass"
              title="Orientation"
              note={orientationNote.note}
              updatedAt={orientationNote.updatedAt}
            />
          )}
          {isDefined(otherNote?.note) && (
            <PolicyInformationSection
              id="other"
              iconType="info"
              title="Other Instructions"
              note={otherNote.note}
              updatedAt={otherNote.updatedAt}
            />
          )}
        </Stack>
      </DialogContent>
    </FullScreenDialog>
  );
}

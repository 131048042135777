import { Text, type TextProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type Theme } from "@mui/material";
import pluralize from "pluralize";

interface SentHomeChargesTextProps extends Omit<TextProps, "color"> {
  sentHomeChargeHours: number;
  color?: (theme: Theme) => string | undefined;
}

export function SentHomeChargesText(props: SentHomeChargesTextProps) {
  const { sentHomeChargeHours, variant = "body2", color: propsColor, ...textProps } = props;

  const hoursText = `${sentHomeChargeHours} ${pluralize("hour", sentHomeChargeHours)}`;

  return (
    <Text
      variant={variant}
      color={(theme) => {
        if (isDefined(propsColor)) {
          return propsColor(theme);
        }

        return theme.palette.text.secondary;
      }}
      {...textProps}
    >
      {sentHomeChargeHours > 0 ? (
        <>
          Shifts cancelled after the scheduled worker has arrived at the workplace will lead to a{" "}
          <strong>{hoursText}</strong> payout.
        </>
      ) : (
        <>
          Shifts cancelled after the scheduled worker has arrived at the workplace{" "}
          <strong>will not have any payout</strong>.
        </>
      )}
    </Text>
  );
}

import {
  AsOfDateText,
  InformationSectionSubtitleContent,
  InformationSectionTextContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Skeleton, Stack } from "@mui/material";
import { type ExtraTimePaySettings } from "@src/appV2/Facilities/api/useGetExtraTimePaySettings";

interface ExtraTimePaySectionProps {
  settings?: ExtraTimePaySettings;
  isLoading: boolean;
  isError: boolean;
}

function getWorkdayOptionDescriptions(workdayOptions?: ExtraTimePaySettings["workdayOptions"]) {
  if (!isDefined(workdayOptions)) {
    return [
      "This workplace does not offer Extra Time Pay for working consecutive hours on a single or back-to-back shifts for any duration of time",
    ];
  }

  return (
    workdayOptions.map(
      (option) =>
        `${option.multiplier}x the pay rate for every consecutive hour worked over ${option.threshold} hours`
    ) ?? []
  );
}

function getWeeklyOptionDescription(
  multiplier: number | undefined,
  threshold: number | undefined,
  isWorkdayOptionEnabled: boolean
) {
  if (!isDefined(multiplier) || !isDefined(threshold)) {
    return "This workplace does not offer Extra Time Pay for working more than 40 hours in a calendar week";
  }

  return `${multiplier}x the pay rate for every hour worked over ${threshold} hours in a calendar week at this workplace from Sunday to Saturday${
    isWorkdayOptionEnabled ? ", if not already earned via a Workday bonus" : ""
  }`;
}

export function ExtraTimePaySection(props: ExtraTimePaySectionProps) {
  const { settings, isLoading, isError } = props;

  const { workdayOptions, weeklyOption } = settings ?? {};

  const isWorkdayOptionEnabled = isDefined(workdayOptions);
  const isWeeklyOptionEnabled = isDefined(weeklyOption);

  if (isLoading) {
    return (
      <Stack spacing={2} aria-label="Loading extra time pay settings">
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Stack>
    );
  }

  if (isError) {
    return (
      <Text variant="body1" color={(theme) => theme.palette.intent?.error.text}>
        Something went wrong while loading extra time pay settings
      </Text>
    );
  }

  return (
    <List dense>
      <AsOfDateText date={new Date()} sx={{ paddingBottom: 4 }} />

      <ListItem>
        <ListItemIcon iconType={isWeeklyOptionEnabled ? "checkmark" : "close"} />
        <ListItemText
          disableTypography
          primary={<InformationSectionSubtitleContent>Workday</InformationSectionSubtitleContent>}
          secondary={getWorkdayOptionDescriptions(workdayOptions).map((description) => (
            <InformationSectionTextContent key={description}>
              {description}
            </InformationSectionTextContent>
          ))}
        />
      </ListItem>

      <ListItem>
        <ListItemIcon iconType={isWeeklyOptionEnabled ? "checkmark" : "close"} />
        <ListItemText
          disableTypography
          primary={<InformationSectionSubtitleContent>Weekly</InformationSectionSubtitleContent>}
          secondary={
            <InformationSectionTextContent>
              {getWeeklyOptionDescription(
                weeklyOption?.multiplier,
                weeklyOption?.threshold,
                isWorkdayOptionEnabled ?? false
              )}
            </InformationSectionTextContent>
          }
        />
      </ListItem>
    </List>
  );
}

import { Text, type TextProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type Theme } from "@mui/material";
import pluralize from "pluralize";

interface LateCancellationTextProps extends Omit<TextProps, "color"> {
  lateCancellationPeriod: number;
  lateCancellationFeeHours: number;
  color?: (theme: Theme) => string | undefined;
}

export function LateCancellationText(props: LateCancellationTextProps) {
  const {
    lateCancellationPeriod,
    lateCancellationFeeHours,
    variant = "body2",
    color: propsColor,
    ...textProps
  } = props;

  const hasLateCancellationPayout = lateCancellationPeriod > 0 && lateCancellationFeeHours > 0;

  const lateCancellationPeriodText = `${lateCancellationPeriod} ${pluralize(
    "hour",
    lateCancellationPeriod
  )}`;
  const lateCancellationFeeHoursText = `${lateCancellationFeeHours} ${pluralize(
    "hour",
    lateCancellationFeeHours
  )}`;

  return (
    <Text
      variant={variant}
      color={(theme) => {
        if (isDefined(propsColor)) {
          return propsColor(theme);
        }

        return theme.palette.text.secondary;
      }}
      {...textProps}
    >
      {hasLateCancellationPayout ? (
        <>
          Shifts cancelled by this workplace within <strong>{lateCancellationPeriodText}</strong>{" "}
          before shift start will lead to a <strong>{lateCancellationFeeHoursText}</strong> payout
          for the scheduled worker.
        </>
      ) : (
        <>
          Shifts cancelled by this workplace <strong>will not have any payout</strong> for the
          scheduled worker.
        </>
      )}
    </Text>
  );
}
